import React from 'react';
import * as s from './styles/globalStyles';

function Footer() {
  return (
    <s.Foot>
      <s.TextSubTitle style={{ color: 'var(--dark-blue)' }}>
        <a
          href='https://twitter.com/ProjectGupi?ref_src=twsrc%5Etfw'
          class='twitter-follow-button'
          data-show-count='false'
        >
          Follow @ProjectGupi on Twitter!
        </a>
      </s.TextSubTitle>

      <s.TextSubTitle>©Copyright 2021 Project Gupi</s.TextSubTitle>
    </s.Foot>
  );
}

export default Footer;
