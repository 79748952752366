import React, { useEffect, useState, useRef } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import * as s from './styles/globalStyles';
import Nav from './Nav';
import Home from './Home';
import Mint from './Mint';
import About from './About';
import Footer from './Footer';

function App() {
  return (
    <Router>
      <s.Screen style={{ backgroundColor: 'var(--dark-blue)' }}>
        <Nav />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/mint' component={Mint} />
          <Route path='/about' component={About} />
        </Switch>
        <Footer />
      </s.Screen>
    </Router>
  );
}

export default App;
