import styled from 'styled-components';

// Used for wrapping a page component
export const Screen = styled.div`
  background-color: var(--bright-blue);
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
`;
export const Hero = styled.div`
  background-color: #777;
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-blend-mode: multiply;
  background-attachment: fixed;
  background-size: cover;
  color: white;
  text-align: center;
  padding: 15vh 1em;
`;
export const Nav = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  background: rgb(1, 221, 233);
  background: linear-gradient(
    25deg,
    rgba(1, 221, 233, 1) 40%,
    rgba(0, 168, 177, 1) 100%
  );
  flex-direction: column;
`;
export const Foot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  background: rgb(1, 221, 233);
  background: linear-gradient(
    200deg,
    rgba(1, 221, 233, 1) 40%,
    rgba(0, 168, 177, 1) 100%
  );
  flex-direction: column;
`;
export const NavLink = styled.li`
  width: 75%;
  display: flex;
  justify-content: space-around;
  list-style: none;
  text-decoration: none;
`;

// Used for providing space between components
export const SpacerXSmall = styled.div`
  height: 8px;
  width: 8px;
`;

// Used for providing space between components
export const SpacerSmall = styled.div`
  height: 16px;
  width: 16px;
`;

// Used for providing space between components
export const SpacerMedium = styled.div`
  height: 24px;
  width: 24px;
`;

// Used for providing space between components
export const SpacerLarge = styled.div`
  height: 32px;
  width: 32px;
`;

// Used for providing a wrapper around a component
export const Container = styled.div`
  display: flex;
  flex: ${({ flex }) => (flex ? flex : 0)};
  flex-direction: ${({ fd }) => (fd ? fd : 'column')};
  justify-content: ${({ jc }) => (jc ? jc : 'flex-start')};
  align-items: ${({ ai }) => (ai ? ai : 'flex-start')};
  background-color: ${({ test }) => (test ? 'pink' : 'none')};
  width: 100%;
  background-image: ${({ image }) => (image ? `url(${image})` : 'none')};
  background-size: cover;
  background-position: center;
`;

export const TextTitle = styled.p`
  color: var(--white);
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
`;
export const TextLogo = styled.p`
  color: var(--near-black);
  font-size: 46px;
  font-weight: 500;
  line-height: 1.4;
  @media (min-width: 767px) {
    font-size: 52px;
    line-height: 1.6;
  }
`;
export const TextH1 = styled.p`
  color: var(--white);
  font-size: 42px;
  font-weight: 500;
  line-height: 1;
  @media (min-width: 767px) {
    font-size: 62px;
    line-height: 1.2;
  }
`;
export const TextH2 = styled.p`
  color: var(--white);
  font-size: 32px;
  font-weight: 500;
  line-height: 1.2;
  @media (min-width: 767px) {
    font-size: 42px;
    line-height: 1;
  }
`;

export const TextSubTitle = styled.p`
  color: var(--white);
  font-size: 14px;
  line-height: 1.6;
`;

export const TextDescription = styled.p`
  color: var(--white);
  font-size: 12px;
  line-height: 1.6;
`;

export const StyledClickable = styled.div`
  :active {
    opacity: 0.6;
  }
`;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: var(--near-black);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;
export const StyledAltButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--yellow);
  padding: 10px;
  font-weight: bold;
  color: var(--near-black);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 200, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 200, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 200, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledForm = styled.form``;

export const StyledInput = styled.input`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: #ffffff;
  padding: 10px;
  font-weight: bold;
  color: #021e42;
  width: 100px;
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledImg = styled.img`
  width: 200px;
  height: 200px;
  @media (min-width: 767px) {
    width: 350px;
    height: 350px;
  }
  transition: width 0.3s;
  transition: height 0.3s;
`;
export const StyledSpan = styled.span`
  color: ${({ tc }) => (tc ? tc : 'var(--bright-blue)')};
  font-weight: 900;
`;
