import React from 'react';
import * as s from './styles/globalStyles';
import { Link } from 'react-router-dom';

function Nav() {
  return (
    <s.Nav>
      <s.TextLogo
        style={{
          fontFamily: 'lobster',
        }}
      >
        <Link to='/'>-Project Gupi-</Link>
      </s.TextLogo>
      <s.Container
        flex={1}
        jc={'center'}
        fd={'row'}
        ai={'center'}
        style={{ paddingBottom: 16, paddingTop: 4 }}
      >
        <Link to='/mint'>
          <s.StyledButton>Mint</s.StyledButton>
        </Link>
        <s.SpacerXSmall />
      </s.Container>
    </s.Nav>
  );
}

export default Nav;
