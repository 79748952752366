import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from './redux/blockchain/blockchainActions';
import { fetchData } from './redux/data/dataActions';
import * as s from './styles/globalStyles';
import i1 from './assets/images/1.png';

function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [feedback, setFeedback] = useState("Maybe it's your lucky day.");
  const [quantity, setQuantity] = useState(1);
  const [claimingNft, setClaimingNft] = useState(false);

  const claimNFTs = (_amount) => {
    if (_amount <= 0) {
      return;
    }
    setFeedback('Minting your Gupi...');
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(blockchain.account, _amount)
      .send({
        gasLimit: 158000 * _amount,
        to: '0x1414647219726D9abBD02AC69cc5e2ac734e4D59',
        from: blockchain.account,
        value: blockchain.web3.utils.toWei((6.0 * _amount).toString(), 'ether'),
      })
      .once('error', (err) => {
        console.log(err);
        setFeedback('Sorry, something went wrong please try again later.');
        setClaimingNft(false);
      })
      .then((receipt) => {
        setFeedback(
          'WOW, you now own a Gen0 Gupi! go visit Opensea.io to view it.'
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const getData = () => {
    console.log(blockchain.account);
    if (blockchain.account !== '' && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen style={{ backgroundColor: 'var(--dark-blue)' }}>
      <s.Container flex={1} ai={'center'} style={{ padding: 24 }}>
        <s.TextTitle
          style={{ textAlign: 'center', fontSize: 42, fontWeight: 'bold' }}
        >
          Mint a Gen 0 Gupi!
        </s.TextTitle>
        <s.SpacerMedium />
        <s.ResponsiveWrapper flex={1} style={{ padding: 24 }}>
          <s.Container flex={1} jc={'center'} ai={'center'}>
            <s.StyledImg alt={'example'} src={i1} />
            <s.SpacerMedium />
            <s.TextTitle
              style={{ textAlign: 'center', fontSize: 46, fontWeight: 'bold' }}
            >
              {data.totalSupply}/3000
            </s.TextTitle>
          </s.Container>
          <s.SpacerMedium />
          <s.Container
            flex={1}
            jc={'center'}
            ai={'center'}
            style={{ backgroundColor: '#011125', padding: 24 }}
          >
            {Number(data.totalSupply) === 3000 ? (
              <>
                <s.TextTitle style={{ textAlign: 'center' }}>
                  The sale has ended.
                </s.TextTitle>
                <s.SpacerSmall />
                <s.TextDescription style={{ textAlign: 'center' }}>
                  You can still find Project Gupi Gen0 on{' '}
                  <a
                    target={'_blank'}
                    href={'https://opensea.io/collection/projectgupi0'}
                  >
                    Opensea.io
                  </a>
                </s.TextDescription>
              </>
            ) : (
              <>
                <s.TextTitle style={{ textAlign: 'center', fontSize: 36 }}>
                  1 Gupi costs 6 MATIC.
                </s.TextTitle>
                {/* <s.TextDescription>
                  *Limited time minting cost!
                </s.TextDescription> */}
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: 'center', fontSize: 24 }}
                >
                  Excluding gas fee.
                </s.TextDescription>
                <s.SpacerSmall />
                <s.TextDescription
                  style={{ textAlign: 'center', fontSize: 16 }}
                >
                  {feedback}
                </s.TextDescription>
                <s.SpacerMedium />
                {blockchain.account === '' ||
                blockchain.smartContract === null ? (
                  <s.Container ai={'center'} jc={'center'}>
                    <s.TextDescription
                      style={{ textAlign: 'center', fontSize: 16 }}
                    >
                      Connect to the Polygon network
                    </s.TextDescription>
                    <s.SpacerSmall />

                    <s.StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT
                    </s.StyledButton>
                    {blockchain.errorMsg !== '' ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription style={{ textAlign: 'center' }}>
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <s.Container ai={'center'} jc={'center'} fd={'row'}>
                    <s.StyledForm>
                      <s.TextDescription
                        style={{ textAlign: 'center', fontSize: 16 }}
                      >
                        Qty(Max 10):
                      </s.TextDescription>
                      <s.StyledInput
                        style={{ textAlign: 'center' }}
                        id='quantity'
                        name='quantity'
                        label='Quantity'
                        value={quantity}
                        onChange={(e) => {
                          e.target.value >= 0 && e.target.value < 11
                            ? setQuantity(e.target.value)
                            : setQuantity(0);
                        }}
                      ></s.StyledInput>
                      <s.SpacerSmall />
                      <s.StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          claimNFTs(quantity);
                          getData();
                        }}
                      >
                        {claimingNft ? 'BUSY' : `BUY ${quantity}`}
                      </s.StyledButton>
                    </s.StyledForm>
                  </s.Container>
                )}
              </>
            )}
          </s.Container>
        </s.ResponsiveWrapper>
        <s.SpacerSmall />
        <s.Container jc={'center'} ai={'center'} style={{ width: '70%' }}>
          <s.TextDescription style={{ textAlign: 'center', fontSize: 16 }}>
            Please make sure you are connected to the right network (Polygon
            Mainnet) and the correct address. Please note: Once you make the
            purchase, you cannot undo this action.
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription style={{ textAlign: 'center', fontSize: 16 }}>
            We have set the gas limit to 158000 for the contract to successfully
            mint your NFT. We recommend that you don't change the gas limit.
          </s.TextDescription>
        </s.Container>
      </s.Container>
    </s.Screen>
  );
}

export default Mint;
