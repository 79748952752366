import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import * as s from './styles/globalStyles';
import wall from './assets/images/gupwall16.jpg';
import wallSq from './assets/images/gupwallsq.jpg';

function Home() {
  return (
    <s.Screen style={{ backgroundColor: 'var(--bright-blue)' }}>
      <s.Hero image={wall}>
        <s.TextH2>Phase 1 is now on OpenSea!</s.TextH2>
        <s.TextH1
          style={{
            fontWeight: 'bold',
          }}
        >
          Come swim with us
        </s.TextH1>
        <a href='https://opensea.io/collection/projectgupi0' target='_blank'>
          <s.StyledAltButton
            style={{
              marginTop: 10,
              width: 175,
              fontSize: 18,
            }}
          >
            Gen 0 Collection
          </s.StyledAltButton>
        </a>
      </s.Hero>
      <s.Container
        style={{ backgroundColor: 'var(--light-blue)', padding: 50 }}
      >
        <s.TextH2
          style={{
            color: 'var(--dark-grey)',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          <s.StyledSpan>Project Gupi</s.StyledSpan> is an upcoming NFT breeding
          & generative art pet simulator on the Polygon Network, inspired by the
          <s.StyledSpan> wonderful diversity</s.StyledSpan> found among fancy
          guppies.
        </s.TextH2>
      </s.Container>
      <s.ResponsiveWrapper style={{ minHeight: 300 }}>
        <s.Container
          image={wallSq}
          flex={1}
          jc={'center'}
          style={{
            backgroundSize: 'cover',
            backgroundColor: 'darkgray',
            backgroundBlendMode: 'multiply',
          }}
        >
          <s.TextH2
            style={{ fontWeight: 'bold', textAlign: 'center', padding: 50 }}
          >
            It is okay to be a Gupi amongst the whales!
          </s.TextH2>
        </s.Container>
        <s.Container
          flex={1}
          jc={'center'}
          style={{
            color: 'var(--dark-grey)',
            padding: 30,
            backgroundColor: 'var(--yellow)',
          }}
        >
          <s.TextDescription
            style={{
              color: 'var(--dark-grey)',
              textAlign: 'center',
              fontSize: 18,
            }}
          >
            Project Gupi is a husband & wife team, we are very novice artist/web
            developer/programmers on a quest to grow our trade and our Gupi. We
            are proud to present an ode to semi-realistic and exotic color
            variations of fancy guppies found around the world 🐟
          </s.TextDescription>
          <s.SpacerSmall />
          <s.TextDescription
            style={{
              color: 'var(--dark-grey)',
              textAlign: 'center',
              fontSize: 18,
            }}
          >
            <s.StyledSpan tc={'var(--near-black)'}>
              500 of 3000 generation 0 Gupi have been minted!
            </s.StyledSpan>
            Each Gupi carries a unique, randomly generated DNA sequence. OUT
            NOW: a <Link to='/mint'>web app</Link> for minting the remaining
            2500 gen 0 gupi, plus breeding and an interactive aquarium
            enviroment.
          </s.TextDescription>
        </s.Container>
      </s.ResponsiveWrapper>
      <s.Container
        jc={'center'}
        ai={'center'}
        style={{
          backgroundColor: 'var(--dark-blue)',
          padding: 50,
        }}
      >
        <s.TextH2 style={{ textAlign: 'center' }}>
          The Gupi are loose! Check the collection here:
        </s.TextH2>
        <a href='https://opensea.io/collection/projectgupi0' target='_blank'>
          <s.StyledButton
            style={{
              marginTop: 10,
              width: 175,
              fontSize: 18,
            }}
          >
            Gen 0 Collection
          </s.StyledButton>
        </a>
      </s.Container>
    </s.Screen>
  );
}

export default Home;
